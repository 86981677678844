import { SVGProps } from 'react'

const EmptyBoxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4063_166006)">
      <path
        d="M40.7705 28.2323C40.7009 28.2323 40.5616 28.2323 40.492 28.1627L31.9989 23.2896L23.5058 28.1627C23.2273 28.302 22.8793 28.2323 22.74 27.9539C22.6008 27.6754 22.6704 27.3273 22.9489 27.1881L31.7204 22.1062C31.8597 22.0366 32.1381 22.0366 32.2774 22.1062L41.0489 27.1881C41.3274 27.3273 41.397 27.6754 41.2578 27.9539C41.1186 28.0931 40.9793 28.2323 40.7705 28.2323Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.9989 44.4523C31.9293 44.4523 31.7901 44.4523 31.7204 44.3827L22.9489 39.3704C22.8096 39.3008 22.6704 39.0919 22.6704 38.8831V32.6873C22.6704 32.4088 22.9489 32.1304 23.2273 32.1304C23.5058 32.1304 23.7843 32.4088 23.7843 32.6873V38.535L31.9989 43.2689L40.2136 38.535V32.6873C40.2136 32.4088 40.492 32.1304 40.7705 32.1304C41.0489 32.1304 41.3274 32.4088 41.3274 32.6873V38.8831C41.3274 39.0919 41.1882 39.3008 41.0489 39.3704L32.2774 44.4523H31.9989Z"
        fill="#CCCCCC"
      />
      <path
        d="M31.9989 44.4522C31.7204 44.4522 31.442 44.1738 31.442 43.8953V33.0353L22.9489 28.1622C22.6704 28.0229 22.6008 27.6748 22.74 27.3964C22.8793 27.1179 23.2273 27.0483 23.5058 27.1875L32.2774 32.2695C32.4166 32.3391 32.5558 32.5479 32.5558 32.7568V43.9653C32.5558 44.2438 32.2774 44.4522 31.9989 44.4522Z"
        fill="#CCCCCC"
      />
      <path
        d="M32 33.2451C31.7912 33.2451 31.652 33.1755 31.5127 32.9666C31.3735 32.6882 31.4431 32.3401 31.7216 32.2009L40.4932 27.1189C40.7716 26.9797 41.1197 27.0493 41.2589 27.3278C41.3982 27.6062 41.3285 27.9543 41.0501 28.0936L32.2785 33.1755C32.2089 33.2451 32.0697 33.2451 32 33.2451Z"
        fill="#CCCCCC"
      />
      <path
        d="M40.7716 28.2321C40.5628 28.2321 40.4235 28.1625 40.2843 27.9537C40.1451 27.6752 40.2147 27.3271 40.4931 27.1879L44.0435 25.169L36.3858 20.7832L32.2785 23.1502C32 23.2894 31.652 23.2198 31.5127 22.9413C31.3735 22.6629 31.4431 22.3148 31.7216 22.1756L36.1074 19.6694C36.2466 19.5998 36.5251 19.5998 36.6643 19.6694L45.4359 24.7513C45.5751 24.821 45.7143 25.0298 45.7143 25.2387C45.7143 25.4475 45.5751 25.6563 45.4359 25.726L41.0501 28.0929C40.9805 28.1625 40.8412 28.2321 40.7716 28.2321Z"
        fill="#CCCCCC"
      />
      <path
        d="M23.2281 28.2324C23.1585 28.2324 23.0193 28.2324 22.9496 28.1628L18.5639 25.6566C18.4246 25.587 18.2854 25.3782 18.2854 25.1693C18.2854 24.9605 18.4246 24.7516 18.5639 24.682L27.3354 19.6001C27.4747 19.5304 27.7531 19.5304 27.8924 19.6001L32.2781 22.1062C32.5566 22.2455 32.6262 22.5935 32.487 22.872C32.3478 23.1505 31.9997 23.2201 31.7212 23.0808L27.6139 20.7139L19.9562 25.0997L23.5066 27.1186C23.785 27.2578 23.8547 27.6059 23.7154 27.8843C23.6458 28.0932 23.437 28.2324 23.2281 28.2324Z"
        fill="#CCCCCC"
      />
      <path
        d="M27.6141 35.7513C27.5445 35.7513 27.4053 35.7513 27.3357 35.6817L18.5641 30.5997C18.4249 30.5301 18.2856 30.3213 18.2856 30.1124C18.2856 29.9036 18.4249 29.6947 18.5641 29.6251L22.9499 27.1189C23.2284 26.9797 23.5764 27.0493 23.7157 27.3278C23.8549 27.6062 23.7853 27.9543 23.5068 28.0936L19.9564 30.1124L27.6141 34.4982L31.7215 32.1313C31.9999 31.992 32.348 32.0616 32.4872 32.3401C32.6265 32.6186 32.5569 32.9666 32.2784 33.1059L27.8926 35.612C27.823 35.7513 27.6838 35.7513 27.6141 35.7513Z"
        fill="#CCCCCC"
      />
      <path
        d="M36.3856 35.7505C36.316 35.7505 36.1767 35.7505 36.1071 35.6809L31.7213 33.1747C31.4429 33.0355 31.3733 32.6874 31.5125 32.409C31.6517 32.1305 31.9998 32.0609 32.2783 32.2001L36.3856 34.567L44.0433 30.1813L40.4929 28.1624C40.2144 28.0232 40.1448 27.6751 40.2841 27.3966C40.4233 27.1182 40.7714 27.0485 41.0498 27.1878L45.4356 29.6939C45.5748 29.7636 45.7141 29.9724 45.7141 30.1813C45.7141 30.3901 45.5748 30.5989 45.4356 30.6686L36.664 35.7505H36.3856Z"
        fill="#CCCCCC"
      />
      <rect
        x="0.857143"
        y="0.857143"
        width="62.2857"
        height="62.2857"
        rx="31.1429"
        stroke="#CCCCCC"
        strokeWidth="1.71429"
        strokeDasharray="3.43 3.43"
      />
    </g>
    <defs>
      <clipPath id="clip0_4063_166006">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EmptyBoxIcon
